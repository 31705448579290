import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { RequirementItem } from './components';

export default function PasswordRequirements({ password }) {
  const { t } = useTranslation();

  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);

  useEffect(() => {
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password));
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasMinLength(password.length >= 8);
  }, [password]);

  return (
    <ul
      style={{
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        marginTop: '10px',
      }}
    >
      <RequirementItem condition={hasNumber} text={t('signUp.reqNumber')} />
      <RequirementItem condition={hasLowerCase} text={t('signUp.reqLowerCase')} />
      <RequirementItem condition={hasUpperCase} text={t('signUp.reqUpperCase')} />
      <RequirementItem condition={hasSpecialChar} text={t('signUp.reqSpecial')} />
      <RequirementItem condition={hasMinLength} text={t('signUp.reqLength')} />
    </ul>
  );
}
